<script setup lang="ts">
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { usePromoCarousel } from '#imports';

import 'swiper/css';
import 'swiper/css/navigation';

const { promoCarouselData, swiperReady, getPromoPath, onClickCarouselItem, onClickSwiperNav, onSlideCarousel } =
  usePromoCarousel();

const modules = [Navigation, Autoplay];
</script>

<template>
  <h2 class="text-gray-darkest font-bold text-extra mb-20">
    {{ $t('hotel.unbeatableoffer') }}
  </h2>

  <div
    class="w-full h-[259px] relative"
    data-testid="banner-promo-card"
  >
    <Swiper
      :autoplay="{
        delay: 5000,
        pauseOnMouseEnter: true,
      }"
      :slides-per-view="'auto'"
      loop
      :navigation="{
        nextEl: '.promo-button-next',
        prevEl: '.promo-button-prev',
      }"
      :modules="modules"
      class="swiper h-full w-full rounded"
      @after-init="swiperReady = true"
      @touch-end="onSlideCarousel"
    >
      <SwiperSlide
        v-for="(item, index) in promoCarouselData"
        :key="index"
        class="!w-[485px] h-full mr-10"
        @click="onClickCarouselItem(item, index)"
      >
        <NuxtLink
          :to="getPromoPath(item)"
          external
          no-rel
        >
          <NuxtImg
            :src="item.img"
            :preload="index === 0"
            :loading="index === 0 ? 'eager' : 'lazy'"
            :fetchpriority="index === 0 ? 'high' : 'low'"
            width="485"
            height="259"
            fit="scale-down"
            sizes="lg:70vw"
            format="webp"
            class="min-w-[485px] h-[259px] rounded"
          />
        </NuxtLink>
      </SwiperSlide>
    </Swiper>

    <ApzButton
      class="swiper-button-prev promo-button-prev hover:!bg-gray-lightest"
      @click="onClickSwiperNav('prev')"
    />

    <ApzButton
      class="swiper-button-next promo-button-next hover:!bg-gray-lightest"
      @click="onClickSwiperNav('next')"
    />
  </div>
</template>

<style scoped lang="scss">
.swiper:not(.swiper-initialized) {
  .swiper-slide + .swiper-slide {
    @apply mr-10;
  }
}

.swiper-button-next,
.swiper-button-prev {
  @apply rounded-full text-gray-darkest bg-white top-0 bottom-0 my-auto shadow-md;

  height: 48px;
  width: 48px;

  &::after {
    @apply font-bold;

    font-size: 18px;
  }
}

.swiper-button-prev {
  @apply -left-[24px];
}

.swiper-button-next {
  @apply -right-[24px];
}
</style>
